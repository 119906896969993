<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li
              class="fd-app-breadcrumb-item"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Categorias de autenticação</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Categorias de
                  <span>Autenticação</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de categorias
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="exibeModalCriarCategoria()"
                          >
                            <small>+ Criar categoria</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Categoria</small>
                                </th>
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Status</small>
                                </th>

                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaCategoriasCarregando">
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaCategorias.length">
                              <tr
                                v-for="categoria in fastSecretariaCategorias"
                                :key="categoria.id_assinatura_plataforma_documento_categoria"
                              >
                                <td class="align-middle text-center">
                                  {{ categoria.nome_assinatura_plataforma_documento_categoria }}
                                </td>                                
                                <td class="align-middle text-center">
                                  <span v-if="categoria.ativo" class="text-success">Ativo</span>
                                  <span v-else class="text-danger">Inativo</span>
                                </td>                                
                                <td class="text-center align-middle">                                  
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarCategoria(categoria)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirCategoria(categoria)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  Nenhum documento cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarCategoria"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova categoria</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarCategoria')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="nome_categoria">Nome da categoria</label>
              <input
                v-model="fastSecretariaCategoria.nome_assinatura_plataforma_documento_categoria"
                type="text"
                id="nome_categoria"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="descricao_categoria">Descrição da categoria</label>
              <input
                v-model="fastSecretariaCategoria.descricao_assinatura_plataforma_documento_categoria"
                type="text"
                id="descricao_categoria"
                class="form-control"
                maxlength="60"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="ativo_categoria">Ativo? </label>
              <input
                v-model="fastSecretariaCategoria.ativo"
                type="checkbox"
                id="ativo_categoria"
                class="form-check-input"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaCategoria()"
                >
                  Criar categoria
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarCategoria"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar categoria</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarCategoria')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="nome_categoria">Nome da categoria</label>
              <input
                v-model="fastSecretariaCategoria.nome_assinatura_plataforma_documento_categoria"
                type="text"
                id="nome_categoria"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="descricao_categoria">Descrição da categoria</label>
              <input
                v-model="fastSecretariaCategoria.descricao_assinatura_plataforma_documento_categoria"
                type="text"
                id="descricao_categoria"
                class="form-control"
                maxlength="60"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 ml-4 d-flex justify-content-between">
              <label for="ativo_categoria">Ativo? </label>
              <input
                v-model="fastSecretariaCategoria.ativo"
                type="checkbox"
                id="ativo_categoria"
                class="form-check-input"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="data_criaca_categoria">Data criação</label>
              <input
                v-model="fastSecretariaCategoria.data_criacao"
                type="input"
                id="data_criaca_categoria"
                class="form-control"
                maxlength="30"
                readonly
              >
            </div>
            <div v-if="fastSecretariaCategoria.id_usuario_ultima_alteracao" class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label for="data_criaca_categoria">Data última alteração</label>
              <input
                v-model="fastSecretariaCategoria.data_ultima_alteracao"
                type="input"
                id="data_criaca_categoria"
                class="form-control"
                maxlength="30"
                readonly
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarTipoDocumento()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirCategoria"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir categoria</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirCategoria')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 my-2">
              <h5>Deseja excluir categoria {{ fastSecretariaCategoria.nome_assinatura_plataforma_documento_categoria }}?</h5>
            </div>
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirCategoria()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaCategoriasDeAutenticacao",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Documento
      fastSecretariaCategoriasCarregando: true,
      fastSecretariaCategorias: [
        {
          id_assinatura_plataforma_documento_categoria: "",
          id_plataforma: "",
          nome_assinatura_plataforma_documento_categoria: "",
          descricao_assinatura_plataforma_documento_categoria: "",
          ativo: "",
          id_usuario_criacao: "",
          data_criacao: "",
          id_usuario_ultima_alteracao: "",
          data_ultima_alteracao: ""
        }
      ],
      fastSecretariaCategoria: {
        id_assinatura_plataforma_documento_categoria: "",
        id_plataforma: "",
        nome_assinatura_plataforma_documento_categoria: "",
        descricao_assinatura_plataforma_documento_categoria: "",
        ativo: false
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Tipo documentos
            this.getSecretariaCategorias(this.$route.params.id_plataforma);
            this.fastSecretariaCategoriasCarregando = false;
          } else {
            this.fastSecretariaCategoriasCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.fastSecretariaCategoriasCarregando = false;
        });
    } else {
      this.fastSecretariaCategoriasCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    getSecretariaCategorias(id_plataforma){
      this.fastSecretariaCategoriasCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_plataforma_documento_categoria/lista_id", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaCategorias", obj);
        if (obj.length) this.fastSecretariaCategorias = obj;
        this.fastSecretariaCategoriasCarregando = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaCategoriasCarregando = false
      })
    },
    exibeModalCriarCategoria(){
      this.fastSecretariaCategoria.nome_assinatura_plataforma_documento_categoria = ""
      this.fastSecretariaCategoria.descricao_assinatura_plataforma_documento_categoria = ""
      this.fastSecretariaCategoria.ativo = false
      this.showModal('modalCriarCategoria')
    },
    async criarSecretariaCategoria() {
      this.fastSecretariaCategoriasCarregando = true
      let obj = {
        id_plataforma: this.$route.params.id_plataforma,
        nome_assinatura_plataforma_documento_categoria: this.fastSecretariaCategoria.nome_assinatura_plataforma_documento_categoria,
        descricao_assinatura_plataforma_documento_categoria: this.fastSecretariaCategoria.descricao_assinatura_plataforma_documento_categoria,
        ativo: this.fastSecretariaCategoria.ativo
      };
      console.log("obj ", obj)
      this.promiseInserirFastApi(obj, "fast_assinatura_plataforma_documento_categoria")
      .then((res) => {
        console.log("criarSecretariaCategoria", res)
        this.exibeToasty("Categoria criada com sucesso", "success");
        this.hideModal("modalCriarCategoria");
        this.getSecretariaCategorias(this.$route.params.id_plataforma);
        this.fastSecretariaCategoriasCarregando = false
      }).catch((e) => {
        console.log(e)
        this.exibeToasty("Erro ao criar requisito", "error");
        this.fastSecretariaCategoriasCarregando = false

      });
    },
    exibeModalEditarCategoria(categoria) {
      this.fastSecretariaCategoria = categoria;
      // this.fastSecretariaCategoria.data_criacao = new Date(this.fastSecretariaCategoria.data_criacao).toLocaleString('pt-br')
      // this.fastSecretariaCategoria.data_ultima_alteracao = new Date(this.fastSecretariaCategoria.data_ultima_alteracao).toLocaleString('pt-br')
      this.showModal("modalEditarCategoria");
    },
    async editarTipoDocumento() {
       this.fastSecretariaCategoriasCarregando = true;
       let obj = {
        id_assinatura_plataforma_documento_categoria: this.fastSecretariaCategoria.id_assinatura_plataforma_documento_categoria,
        id_plataforma: this.$route.params.id_plataforma,
        nome_assinatura_plataforma_documento_categoria: this.fastSecretariaCategoria.nome_assinatura_plataforma_documento_categoria,
        descricao_assinatura_plataforma_documento_categoria: this.fastSecretariaCategoria.descricao_assinatura_plataforma_documento_categoria,
        ativo: this.fastSecretariaCategoria.ativo
      };
        this.promiseAtualizarFastApi(obj, "fast_assinatura_plataforma_documento_categoria").then(e => {
          this.exibeToasty("Categoria alterada com sucesso", "success");
          this.getSecretariaCategorias(this.$route.params.id_plataforma);
          this.fastSecretariaCategoriasCarregando = false;
          this.hideModal("modalEditarCategoria");
        }).catch(e => {
          console.log(e)
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.fastSecretariaCategoriasCarregando = false;
        }
        );
    },
    exibeModalExcluirCategoria(categoria) {
      this.fastSecretariaCategoria = categoria;
      this.showModal("modalExcluirCategoria");
    },
    async excluirCategoria(){
      this.fastSecretariaCategoriasCarregando = true;
       let obj = {
        id_assinatura_plataforma_documento_categoria: this.fastSecretariaCategoria.id_assinatura_plataforma_documento_categoria,
        id_plataforma: this.$route.params.id_plataforma,
      };
      this.promiseExcluirFastApi(obj, "fast_assinatura_plataforma_documento_categoria").then(e => {
        this.exibeToasty("Documento excluído com sucesso", "success");        
        this.getSecretariaCategorias(this.$route.params.id_plataforma);   
        this.hideModal("modalExcluirCategoria");
      }).catch(e => {
        console.log(e)
        this.exibeToasty("Erro ao excluir", "error");
        this.fastSecretariaCategoriasCarregando = false;
      });
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
